function PreviewCard({ title, author }) {
  if (!title) {
    title =
      " I am the descriptinjkdsfnv jksfn sjdafkdsjfnb fsdbfbsdhf jshdfb fbjhsaddsda dsa        bsac sc saca  I am the descriptinjkdsfnv jksfn sjdafkdsjfnb fsdbfbsdhf jshdfbfbjhsad        bsac sc saca";
  }
  return (
    <>
      <li className="preview-card">
        <section className="image">
          <img src="/Images/img001.jpg"></img>
        </section>
        <section>
          <p className="author-name">By Amanuel</p>
          <p className="title">{title}</p>
        </section>
      </li>
    </>
  );
}
export default PreviewCard;
