export function Footer() {
  return (
    <footer>
      
        <ul>
          <li>
            <a href="">Privacy Policies</a>
          </li>
          <li>
            <a href="">Terms and Conditions</a>
          </li>
          <li>
            <a href=""> Cookie Management</a>
          </li>
        </ul>
      
      <hr />
      <section className="footer-text-comp">
        <p>
          © 2022 Anyways, Inc. Anyways, and any associated logos are trademarks,
          service marks, and/or registered trademarks of Anyways, Inc.
        </p>
        <h3>Anyways ©</h3>
      </section>
    </footer>
  );
}
export default Footer;